import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { Box, Heading, Link as ExternalLink, Image } from 'theme-ui'
import { FaMapPin, FaClock, FaImages, FaCalendarDay } from 'react-icons/fa'
import Lightbox from 'react-image-lightbox'

var ts = require('@mapbox/timespace')

export default function GalleryTemplate(props) {
  const {
    albumCover,
    albumName,
    albumSubtitle,
    images,
    childContentfulGalleryDescriptionTextNode,
    location,
    updatedAt,
  } = props.data?.contentfulGallery

  const heroBackground = {
    backgroundImage: `url(${albumCover.file.url})`,
  }

  const lat = location.lat.toFixed(4)
  const lon = location.lon.toFixed(4)

  const [currentTime, setCurrentTime] = useState(
    ts.getFuzzyLocalTimeFromPoint(Date.now(), [lon, lat]).format('hh:mm a')
  )

  const [lightBox, setLightBox] = useState({
    photoIndex: 0,
    isOpen: false,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      const time = ts
        .getFuzzyLocalTimeFromPoint(Date.now(), [lon, lat])
        .format('hh:mm a')
      setCurrentTime(time)
    }, 15000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.hero, ...heroBackground }}>
        <Heading as='h1' sx={styles.albumName}>
          {albumName}
        </Heading>
        <Heading as='h3' sx={styles.albumSubtitle}>
          {albumSubtitle}
        </Heading>
      </Box>
      <Box sx={styles.details}>
        <Heading as='h5' sx={styles.detail}>
          <FaClock />
          Current Time: {` ${currentTime}`}
        </Heading>

        <Heading as='h5' sx={styles.detail}>
          <ExternalLink
            href={`https://www.google.com/maps/search/${lat},${lon}/@${lat},${lon}`}
            rel='noopener'
          >
            <FaMapPin />
            Lat: {lat}, Long: {lon}
          </ExternalLink>
        </Heading>

        <Heading as='h5' sx={styles.detail}>
          <FaImages />
          {images.length} Images
        </Heading>

        <Heading as='h5' sx={styles.detail}>
          <FaCalendarDay />
          Updated: {updatedAt}
        </Heading>
      </Box>
      <Box sx={styles.content}>
        <Box
          sx={styles.description}
          dangerouslySetInnerHTML={{
            __html:
              childContentfulGalleryDescriptionTextNode.childMarkdownRemark
                .html,
          }}
        />
      </Box>

      <Box sx={styles.gallery}>
        {images.map((image, index) => (
          <Box sx={styles.imageContainer}>
            <Image
              sx={styles.image}
              src={image.file.url}
              alt={image.title}
              onClick={() => setLightBox({ photoIndex: index, isOpen: true })}
            />
          </Box>
        ))}
      </Box>
      {console.log(images[lightBox.photoIndex].file.url)}
      <Box sx={styles.lightBoxContainer}>
        {lightBox.isOpen && (
          <Lightbox
            mainSrc={images[lightBox.photoIndex].file.url}
            nextSrc={images[(lightBox.photoIndex + 1) % images.length]}
            prevSrc={
              images[(lightBox.photoIndex + images.length - 1) % images.length]
                .file.url
            }
            onCloseRequest={() => setLightBox({ ...lightBox, isOpen: false })}
            onMovePrevRequest={() =>
              setLightBox({
                ...lightBox,
                photoIndex:
                  (lightBox.photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              setLightBox({
                ...lightBox,
                photoIndex: (lightBox.photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </Box>
    </Box>
  )
}

const styles = {
  container: {},
  hero: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    flexDirection: 'column',
  },
  albumName: {
    fontSize: ['2.5rem', '', '3.5rem', '5rem'],
    color: 'white',
  },
  albumSubtitle: {
    fontSize: ['1.75rem', '', '2rem', '2.5rem'],
    color: 'secondary',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '2rem 1rem',
  },
  details: {
    padding: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: 'dark',
    // flexDirection: 'column',
    color: 'secondary',
    alignItems: 'center',
    justifyContent: 'center',
    width: ['100%'],
  },
  detail: {
    margin: '0.5rem 1rem',
    fontSize: '1rem',
    color: 'secondary',
    svg: {
      marginRight: '0.5rem',
    },
    a: {
      color: 'secondary',
      ':hover': {
        color: 'white',
      },
    },
  },
  description: {
    width: ['100%', '', '75%'],
    padding: '1rem',
    p: {
      margin: '0rem',
      marginBottom: '1rem',
    },
    a: {
      color: 'secondary',
    },
  },

  gallery: {
    display: 'flex',
    flexWrap: 'wrap',
    'div:nth-of-type(4n+1)': {
      width: 'calc(33.3% - 0.5rem * 2)',
    },
    'div:nth-of-type(4n+4)': {
      width: 'calc(33.3% - 0.5rem * 2)',
    },

    'div:nth-of-type(4n+2)': {
      width: 'calc(66.6% - 0.5rem * 2)',
    },
    'div:nth-of-type(4n+3)': {
      width: 'calc(66.6% - 0.5rem * 2)',
    },
  },
  imageContainer: {
    width: 'calc(33.3% - 0.5rem * 2)',
    margin: ' 0.25rem 0.5rem',
  },
  image: {
    height: ['250px', '', '500px'],
    width: '100%',
    objectFit: 'cover',
  },
  lightBoxContainer: {
    zIndex: '9999',
  },
}

export const query = graphql`
  query SingleGallery($id: String) {
    contentfulGallery(id: { eq: $id }) {
      albumCover {
        file {
          url
        }
        description
        title
      }
      albumName
      albumSubtitle
      childContentfulGalleryDescriptionTextNode {
        childMarkdownRemark {
          html
        }
      }
      images {
        file {
          url
        }
        description
        title
      }
      location {
        lat
        lon
      }
      updatedAt(formatString: "DD MMM yyyy")
    }
  }
`
